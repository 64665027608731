<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
emailAccountComputed,
  emailAccountMethods,
  emailAccountMutations,
} from "../../../state/helpers/eaccount";
import { communicationChannelComputed, communicationChannelMethods} from  "../../../state/helpers/communicationchannel";
import { required } from "vuelidate/lib/validators";
import { inboxMethods } from "../../../state/helpers/inbox";
// import Echo from 'laravel-echo'
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {},
  data() {
    return {
      account_title: null,
      smtp_host: null,
      imap_host: null,
      smtp_port: "587",
      imap_port: "993",
      fullname : null,
      smtp_encryption: "tls",
      smtp_username:"",
      smtp_password:"",
      imap_encryption: "ssl",
      username: null,
      password: null,
      communication_channel_id:null,
      icon_color: "#fff",
      icon: "far fa-envelope",
      smtp_type_id: 1,
      imap_type_id: 1,
      imap_protocol:"imap",
      imap_validate_cert:true,
      is_edited: false,
      account_id: null,
      oauth_id: null,
      submit: false,
      channel_type_id:1,
      imap_username:null,
      imap_password:null,
      title: "Advanced Table",
      
      items: [
        {
          text: "Tables",
          href: "/",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      email_account: null,
      startIndex: 0,
      fields: [
        { key: "email_account.fullname",  label: "Fullname", sortable: true },
        { key: "title", sortable: true },
        { key: "email_account.username", label: "Username", sortable: true },
        {key: "status",label:"Connection Status"},
        {key:"action",label:"Action"}
      ],
      smtp_type: true,
      createEmailAccountSuccess: false,
      oauth_token: null,
    };
  },
  validations: {
    account_title: { required },
    username: { required },
    password: { required },
  },
  computed: {
    ...communicationChannelComputed,
    ...emailAccountComputed,
    tableData:{
      get:function(){
        return this.getAllChannels.filter((item) => item.channel_type_id === 1);
      }, 
      set:function(value){
        return value
      }
    },
      gmail_url:{
      get:function(){
        return this.getCodeUrl;
      }, 
    },
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {

    
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.loadSMTPAccountType();
    this.loadImapAccountType();
    this.listenForEmails();


  document.addEventListener("visibilitychange", () => {
  if (document.visibilityState == "visible") {
        this.allChannel();
        localStorage.removeItem("fullname");
        localStorage.removeItem("account_title");
        localStorage.removeItem("icon_color");
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        localStorage.removeItem("smtp_type_id");
        localStorage.removeItem("imap_type_id");
        localStorage.removeItem("icon");
  } else {
    console.log("tab is inactive")
  }
 
});
  },

  methods: {
    ...emailAccountMethods,
    ...emailAccountMutations,
    ...inboxMethods,
    ...communicationChannelMethods, 
    listenForEmails(){
                // const echo = new Echo({
                //     broadcaster: 'socket.io',
                //     host: '127.0.0.1:6003',
                //     forceTLS: false,
                //     transports: ['websocket'],
                // });
                //  echo.channel("channel-created").listen("ListernGmailAccountEvent",(message)=>{
                //   if(message){
                //     this.all();
                //     this.allChannel();
                //   }
                    
                // });
            }, 
    testImapConnect(id){
      this.testImapConnection(id).then(()=>{
        this.allChannel();
      })
    },  
    deleteData() {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete this email account !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then(() => {
        
        this.deleteChannel(this.communication_channel_id)
          .then(() => {
            this.allChannel();
              this.$modal.hide("email-account-view-modal");
              this.emptyData();
           
          })
          .catch((error) => {
            this.$bvToast.toast(`${error.error}`, {
              title: `Error !`,
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "danger",
            });
          });
      });
    },
    loadEmailAccounts() {
      // return this.all().then(() => {
      //   this.tableData = this.email_accounts.data;
      // });
    },
    loadSMTPAccountType() {
      // load smtp account from store
      return this.smtpAccountType();
    },
    loadImapAccountType() {
      return this.imapAccountType();
    },
    beforeTabSwitch() {
      // // stop here if form is invalid
      //  this.startIndex = "0";
      this.$v.account_title.$touch();
      this.$v.username.$touch();
      this.$v.password.$touch();
      return true;
    },
    createEmailAccount() {
      const account = {
        fullname:this.fullname,
        smtp_host: this.smtp_host,
        imap_host: this.imap_host,
        smtp_port: this.smtp_port.toString(),
        imap_port: this.imap_port.toString(),
        smtp_encryption: this.smtp_encryption,
        imap_encryption: this.imap_encryption,
        imap_username:this.imap_username,
        imap_password:this.imap_password,
        username: this.username,
        password: this.password,
        smtp_type_id: this.smtp_type_id.toString(),
        imap_type_id: this.imap_type_id.toString(),
        imap_protocol:this.imap_protocol,
        imap_validate_cert:this.imap_validate_cert,
      };

      const com_channel = {
        channel_type_id:this.channel_type_id,
        title: this.account_title,
        icon: this.icon,
        icon_color: this.icon_color,
      }

      this.addChannel({com_channel, account, code:""}).then(() => {
        this.allChannel();
        this.$modal.hide("email-account-view-modal");
        this.emptyData();
      });
    },

    updateEmailAccount() {
      const account = {
        id:this.account_id,
        fullname:this.fullname,
        smtp_host: this.smtp_host,
        imap_host: this.imap_host,
        smtp_port: this.smtp_port.toString(),
        imap_port: this.imap_port.toString(),
        smtp_encryption: this.smtp_encryption,
        imap_encryption: this.imap_encryption,
        imap_username:this.imap_username,
        imap_password:this.imap_password,
        username: this.username,
        password: this.password,
        smtp_type_id: this.smtp_type_id.toString(),
        imap_type_id: this.imap_type_id.toString(),
        imap_protocol:this.imap_protocol,
        imap_validate_cert:this.imap_validate_cert,
      };

      const com_channel = {
        id:this.communication_channel_id,
        channel_type_id:this.channel_type_id,
        title: this.account_title,
        icon: this.icon,
        icon_color: this.icon_color,
      }

      this.updateChannel({com_channel, account, code:""}).then(() => {
        this.allChannel();
        this.$modal.hide("email-account-view-modal");
        this.emptyData();
      });
    },

    /**
     *
     * generate token url
     */

    generateCodeUrl() {

      this.codeUrl()
        .then(() => {
          localStorage.setItem("fullname", this.fullname);
          localStorage.setItem("account_title", this.account_title);
          localStorage.setItem("icon_color", this.icon_color);
          localStorage.setItem("username", this.username);
          localStorage.setItem("password", this.password);
          localStorage.setItem("smtp_type_id", this.smtp_type_id);
          localStorage.setItem("imap_type_id", 2);
          localStorage.setItem("icon", this.icon);
          
          this.$modal.hide("email-account-view-modal");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editOpenModal(record) {
      this.communication_channel_id  = record.id;
      this.fullname = record.email_account.fullname;
      this.account_title = record.title;
      this.account_id = record.email_account.id;
      this.smtp_host = record.email_account.smtp_host;
      this.imap_host = record.email_account.imap_host;
      this.smtp_port = record.email_account.smtp_port;
      this.imap_port = record.email_account.imap_port;
      this.smtp_encryption = record.email_account.smtp_encryption;
      this.imap_encryption = record.email_account.smtp_encryption;
      this.username = record.email_account.username;
      this.password = record.email_account.password;
      this.icon_color = record.icon_color;
      this.icon = record.icon;
      this.smtp_username = record.email_account.smtp_username;
      this.smtp_password = record.email_account.smtp_password;
      this.imap_username = record.email_account.imap_username;
      this.imap_password = record.email_account.imap_password;
      this.smtp_type_id = record.email_account.smtp_type_id;
      this.imap_type_id = record.email_account.imap_type_id;
      this.smtp_type = record.email_account.smtp_type_id == 1 ? true : false;
      this.is_edited = true;
      this.$modal.show("email-account-view-modal");
    },
    switchSMTPType() {
      return this.smtp_type = !this.smtp_type;
    },
    handleClose() {
      this.emptyData();
    },

    emptyData(){
      this.fullname = null;
      this.account_title = null;
      this.smtp_host = null;
      this.imap_host = null;
      this.smtp_port = "587";
      this.imap_port = "993";
      this.smtp_encryption = "tls";
      this.imap_encryption = "ssl";
      this.username = null;
      this.password = null;
      this.smtp_type_id = 1;
      this.imap_type_id = 1;
      this.smtp_type = true;
      this.is_edited = false;
      this.icon = "far fa-envelope"
    },
    changeUsername(e){
      let value = e.target.value;
      this.smtp_username = value;
      this.imap_username = value;
    },
    changePassword(e){
      let value = e.target.value;
      this.smtp_password = value;
      this.imap_password = value;
    }
  },

};
</script>

<template>
  <div class="row">
    <!-- modal view -->
    <modal
      name="email-account-view-modal"
      :width="1000" height="auto" draggable=".modal-header"
    >
             <div class="modal-header">
        <span>{{ is_edited ? 'Update Email Account' : 'Create New Email Account' }}</span>
        <i class="mdi mdi-close-thick" @click="$modal.hide('email-account-view-modal')"></i>
      </div>

            <div class="modal-body-view">
          <div class="row">
                <div class="col-6">
                   <label
                  class="col-form-label"
                  for="smtp_type_id"
                  >Account Type</label
                >
                <select
                    name="name"
                    v-model="smtp_type_id"
                    class="form-control"
                    @change="switchSMTPType"
                    read-only
                  >
                    <option
                      v-for="(item, index) in smtp_account_type"
                      :key="index"
                      :value="item.id"
                      >{{ item.value }}</option
                    >
                  </select>
            </div>
            <div class="col-6">
               <label class="col-form-label" for="fullname"
                  >Fullname</label
                >

                  <input
                    id="fullname"
                    type="text"
                    class="form-control"
                    name="fullname"
                    value=""
                    v-model="fullname"
                    
                  />
            </div>
            <div class="col-6 mt-1">
                <label class="col-form-label" for="account_title"
                  >Title</label
                >
                     <input
                    id="account_title"
                    type="text"
                    class="form-control"
                    name="account_title"
                    value=""
                    v-model="account_title"
                    
                  />
             </div>
 <div class="col-6 mt-1">
   <label class=" col-form-label" for="username"
                  >Username</label
                >
                   <input
                    id="username"
                    type="text"
                    class="form-control"
                    name="username"
                     @change="changeUsername"
                    v-model.trim="$v.username.$model"
                    value=""
                    
                  />
                    </div>

            <div class="col-6 mt-1">
              <label class="col-form-label" for="password"
                  >Password</label
                >
                  <input
                    id="password"
                    type="text"
                    name="password"
                    v-model="password"
                    @change="changePassword"
                    class="form-control"
                    value=""
                   
                  />
              </div>

  <div class="col-md-6 mt-1">
    <label class=" col-form-label" for="color"
                      >Color</label
                    >
                      <input
                        id="color"
                        type="color"
                        name="color"
                        v-model="icon_color"
                        class="form-control"
                        value=""
                      />
                </div>
            <!-- end col -->
          </div>
          <!-- end row -->
          <hr />
          <!-- <h5 class="mt-2 mb-2">Server Details</h5> -->

          <div class="row" v-if="smtp_type === true">
        
            <div class="col-12 mt-1">
                <label class=" col-form-label" for="smtp_host"
                  >SMTP Host</label
                >

                  <input
                    type="text"
                    name="smtp_host"
                    v-model="smtp_host"
                    class="form-control"
                    value=""
                  />
            </div>
                  <div class="col-6">
                     <label class=" col-form-label" for="smtp_username"
                  >SMTP Username</label
                >
                  <input
                    type="text"
                    name="smtp_username"
                    v-model="smtp_username"
                    class="form-control"
                    value=""
                  />
            </div>

                  <div class="col-6">
                     <label class="col-form-label" for="smtp_password"
                  >SMTP Password</label>
                  <input
                    type="text"
                    name="smtp_password"
                    v-model="smtp_password"
                    class="form-control"
                    value=""
                  />
            
            </div>

            <div class="col-6 mt-1">
                 <label class="col-form-label" for="smtp_port"
                  >SMTP Port</label
                >
                  <input
                    type="text"
                    v-model="smtp_port"
                    name="smtp_port"
                    class="form-control"
                    value=""
                  />
            </div>

            <div class="col-6 mt-1">
                <label class="col-form-label" for="smtp_encryption"
                  >Smtp Encryption</label
                >
                   <input
                    type="text"
                    name="smtp_encryption"
                    v-model="smtp_encryption"
                    class="form-control"
                    placeholder="default = tls"
                    value=""
                  />
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
            <hr />
          <!-- <h5 class="mt-2 mb-2">Imap Details</h5> -->
          <div class="row" v-if="smtp_type === true">
            <div class="col-12 mt-1">
                <label
                  class="col-form-label"
                  for="imap_account_type_id"
                  >IMAP Account Type</label
                >
                  <select
                    name="imap_account_type_id"
                    v-model="imap_type_id"
                    class="form-control"
                  >
                    <option
                      v-for="(item, index) in imap_account_type"
                      :key="index"
                      :value="item.id"
                      >{{ item.value }}</option
                    >
                  </select>
           
            </div>
            <div class="col-6 mt-1">
               <label class=" col-form-label" for="imap_host"
                  >IMAP Host</label
                >
                 <input
                    type="text"
                    name="imap_host"
                    v-model="imap_host"
                    class="form-control"
                    value=""
                  />
            </div>
            <div class="col-6 mt-1">
              <label class="col-form-label" for="imap_port"
                  >IMAP Port</label
                >
                  <input
                    type="text"
                    v-model="imap_port"
                    name="imap_port"
                    class="form-control"
                    value=""
                  />
            </div>

             <div class="col-6 mt-1">
               <label class="col-form-label" for="imap_username"
                  >IMAP Username</label
                >
                  <input
                    type="text"
                    name="imap_username"
                    v-model="imap_username"
                    class="form-control"
                    value=""
                  />
            </div>

            
             <div class="col-6 mt-1">
               <label class="col-form-label" for="imap_password"
                  >IMAP Password</label
                >
                 <input
                    type="text"
                    name="imap_password"
                    v-model="imap_password"
                    class="form-control"
                    value=""
                  />
            </div>

            <!-- end col -->
          </div>
          <!-- end row -->
          
          <div class="account-modal-footer-button">
              <div class="delete-button-box" @click="deleteData" v-if="is_edited">
        <b-button variant="danger"
          ><i class="far fa-trash-alt"></i
        ></b-button>
      </div>
      <div></div>
 <div class="mt-3">
         
               <b-button
                        v-if="smtp_type === false && is_edited === false"
                        class="mt-3"
                        variant="success"
                        @click="generateCodeUrl"
                        > Login to gmail to complete account settings </b-button
                      >
             <b-button variant="success" v-if="is_edited === true" @click="updateEmailAccount">Save Changes</b-button>
             <b-button variant="success" v-if="smtp_type === true && is_edited === false" @click="createEmailAccount">Save</b-button>
        </div>
          </div>
            </div>

       
    </modal>
    <!-- modal view ends -->

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="c-title-view-box">
            <h4 class="card-title"><span>Email Acccount</span></h4>

            <b-button variant="primary" @click="$modal.show('email-account-view-modal')"
              >Add New</b-button
            >
          </div>

          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="editOpenModal"
            >
            <template #cell(action)="data">
              <div v-if="data.item.email_account.imap_host">
                <b-button variant="success" @click="testImapConnect(data.item.email_account.id)">Test Connection</b-button>
              </div>
              <div v-else>
                <b-badge variant="secondary">N/A</b-badge>
              </div>
             </template>
            <template #cell(status)="data">
              <div v-if="data.item.email_account.status">
                <b-badge variant="success">SUCCESS</b-badge>
              </div>
              <div v-else>
                <b-badge variant="danger">FAILED</b-badge>
              </div>
             </template>
          </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
