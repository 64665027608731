<script>
import EmailAccountView from "./content/index.vue";
/**
 * Starter page component
 */
export default {
  page: {
    title: "Email Settings",
    meta: [{ name: "this is the email settings view" }]
  },
  components: {EmailAccountView },
  data() {
    return {
      title: "Email Account Settings",
      items: [
        {
          text: "Utility",
          href: "/"
        },
        {
          text: "Starter page",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <div>
    <div class="container">
        <EmailAccountView />
    </div> 
  </div>
</template>