import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const inboxComputed = {
    ...mapState('inbox', {
        email_inboxs:(state) => state.email_inboxs,
        reply_later_emails: (state) => state.reply_later_emails
    }),
    ...mapGetters('inbox', ['getAllInbox', 'getTotalRows','getAllReadLaterEmails', 'getAllReplyLaterEmails','getAllWriteLaterEmails', 'getTotalRows'])
}

// export email account actions
export const inboxMethods = mapActions('inbox', ['allInbox', 'moveEmailMessageToFolder', 'allGroupInbox', 'folderEmails', 'emptyInbox', 'deleteEmails', 'updateEmail', 'allReadLaterEmails',
'deleteReadLater','addReadLater', 'updateReadLater', 'allReplyLaterEmails', 'deleteReplyLater', 'updateReplyLater', 'createReplyLater', 'allWriteLaterEmails', 'deleteWriteLater', 'updateWriteLater',
])